import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import ArrowSvg from '../atoms/arrowSvg'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 60px;
  border: solid 2px #bef1e5;
  border-radius: 5px;
  padding: 20px;
  position: relative;

  .intro-wrap {
    background-color: #35d4c7;
    position: absolute;
    padding: 10px 20px;
    left: -10px;
    top: 15px;
  }

  .intro-txt {
    font-size: 18px;
    line-height: 1;
    color: #fff;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  .detail-txt {
    line-height: 24px;
    margin-top: 45px;
    margin-bottom: 15px;
    padding: 0;
  }

  .btn-wrap {
    display: flex;
    width: 100%;
    justify-content: center;

    button {
      height: 60px;
      margin: 0;
    }

    .btn-inner {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        padding: 0;
        margin-right: 10px;
        font-size: 23px;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：C0034用に追加
 * @constructor
 */
const Introduction014 = () => {
  const routePram = useRouteParam('https://www.kintaicloud.jp/')
  return (
    <Wrapper>
      <div className="intro-wrap">
        <p className="intro-txt">休暇管理をもっと楽に！</p>
      </div>
      <p className="detail-txt">
        「キンクラ」では、休暇の申請から承認までが簡単に行えます。
        <br />
        また、休暇の自動付与やアラート機能で、法律に準拠した休暇管理を実現します。
        <br />
        まずは、最大2ヶ月の無料トライアルをお試しください。
      </p>
      <div className="btn-wrap">
        <OfficialCommonBtn
          size="mega"
          level="strong"
          onClick={() => window.open(routePram)}
          className="strong-btn"
        >
          <div className="btn-inner">
            <p>製品情報</p>
            <ArrowSvg />
          </div>
        </OfficialCommonBtn>
      </div>
    </Wrapper>
  )
}

export default Introduction014
