import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction014 from './Introduction014'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0034 = () => (
  <Wrapper>
    <ColumnH1 label="有給休暇がもらえない？休職時における有給休暇の付与について" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.09.12</p>
    <section>
      <img src="/images/column/details/c0034.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        負傷や疾病などの理由で労務の提供が困難となり、休職となるケースは決して珍しくありません。しかし、休職は労働基準法上定めがなく、会社独自に定めておく必要があります。
        <br />
        そこで、勤続年数に応じて付与される有給休暇と休職についてはどのような形で付与されるのかにフォーカスをあて解説してまいります。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">休職とは</a>
          </li>
          <li>
            <a href="#toc2">休職時の有給休暇</a>
          </li>
          <li>
            <a href="#toc3">休職時の有給休暇付与</a>
          </li>
          <li>
            <a href="#toc4">有給休暇の時効</a>
          </li>
          <li>
            <a href="#toc5">最後に</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>休職とは</h1>
      </div>
      <p>
        まず、休職とは負傷や疾病などの理由により<span>働けなくなった場合</span>
        に議論されます。
        <br />
        通常、労働契約とは労働者が会社に対して労務の提供をして会社が労働者に賃金を支払うことを双方が合意した契約です。よって働けなくなったということは、理論上、債務不履行となり解雇が議論されるところではありますが、日本の労働法制上、解雇は簡単にできるものではありません。
        <br />
        そこで、労務の提供が困難な期間を<span>「休職」</span>
        として扱い、<span>労務の提供を免除する制度</span>
        が多く採用されています。仮に休職制度がない場合を想定すると、特に近年は外見からは察知し難い精神疾患も増えてきており、突発的に働けなくなった場合、直ちに解雇するのかという話にもなってしまいます。
        <br />
        そこで、休職制度のメリットを感じ、休職制度を設ける企業は増えています。
      </p>
      <div id="toc2">
        <h1>休職時の有給休暇</h1>
      </div>
      <p>
        休職時であっても有給休暇の権利を行使することは可能です。労働者がどのような理由で有給休暇の権利を行使するのかは会社が干渉できない部分です。よって、
        <span>残日数の限り行使することは可能</span>です。
        <br />
        しかし、有給休暇の残日数がなくなってしまった場合は休職を選択せざるを得ないこととなります。休職は労働基準法にも条文がないことから
        <span>休職期間中の賃金支払い義務はありません</span>
        。すなわち、休職期間中無給とすること自体は問題ありません。尚、継続して4日以上働けなくなった場合、健康保険から支給される傷病手当金を活用するなどが一般的な選択肢です。
      </p>
      <Introduction014 />
      <div id="toc3">
        <h1>休職時の有給休暇付与</h1>
      </div>
      <p>
        有給休暇は
        <span>
          6か月以上継続勤務し、全労働日の8割以上の出勤率を満たしている
        </span>
        ことで10日が付与されます。その後、1年を経過するごとに11日、更に1年後経過すると12日と増えていき、6年6ヶ月以上では20日付与を上限として法律上定められています。
        <br />
        <br />
        今回のメインテーマである休職時の有給休暇の付与の考え方は全労働日の8割をどう解釈するのかになりますが、私傷病による休職の場合は、
        <span>
          出勤率算出の際に、（法律上では）出勤したものとみなすことはできません
        </span>
        。逆に業務上負傷や疾病により休業した期間は出勤したものとみなすことから分けて理解する必要があります。業務災害の場合、会社の責任もあることからこのような考え方となっています。
        <br />
        この8割という数字は労働者の勤怠状況を把握し、特に出勤率の低い労働者を除外する趣旨です。当然、10割近い出勤率を継続する労働者と5割程度の出勤率の労働者が同じ日数の有給休暇が付与される状態では就労意欲にも影響が出ると考えます。
        <br />
        しかし、これはあくでまで法律上の<span>「最低基準」</span>
        であり、法律上の基準を下回らなければ違法とならなないという考え方であるため、長期雇用のためのインセンティブとして、休職期間中も出勤したものとみなすという考え方を採用すること自体は問題ありません。
        <br />
        <br />
        法律上の考え方で解説すると例えば6か月経過時に8割以上の出勤率を維持し、10日の有給休暇を付与された労働者が諸般の事由（私傷病が理由）により、6か月経過後から1年6か月経過時に出勤率が8割を満たさなかった場合、
        <span>有給休暇は発生しない</span>ということです。
        <br />
        <br />
        そして、1年6か月経過後から2年6か月経過時に再び8割の出勤率を満たした場合は、11日ではなく、12日が付与されます。
      </p>
      <a href="/column/details/C0072/" className="column-detail-link">
        参照記事：有給管理を効率化する勤怠管理システムの選び方やお勧めのシステムをご紹介
      </a>
      <br />
      <br />
      <div id="toc4">
        <h1>有給休暇の時効</h1>
      </div>
      <p>
        休職中の場合、それが継続した休職なのか断続した休職なのかにもよりますが、有給休暇の時効についてもおさえておく必要があります。
        <br />
        2020年4月1日の改正労働基準法施行後も有給休暇の時効は<span>2年</span>
        のままです（残業代は5年（当分の間は3年））。そこで、問題となるのが前年度から保持する有給休暇を翌年度に繰り越した場合、例えば病気療養のため実際に行使する有給休暇は前年度からの繰り越し分なのか、今年度付与されたばかりの有給休暇なのかという疑問が生じ得ます。
        <br />
        結論としては労働基準法上、どちらから行使しなければならないという規定はありません。そこで、混乱を招かないよう就業規則に規定することが適切と考えます。
        <br />
        もし就業規則に定めた場合はその内容が合理的な内容であることが前提ですが、その内容に従っていくこととなります。
      </p>
      <div id="toc5">
        <h1>最後に</h1>
      </div>
      <p>
        有給休暇付与において、傷病休職時を出勤したものとみなす必要はなく、いわゆる
        <span>欠勤と同じ扱い</span>
        となります。しかし、法律では最低基準を定めているに過ぎないことから、それを上回る措置を講じることは問題ありません。
        <br />
        人生100年時代となり長く働くことが前提となりつつある現代において休職は決して珍しいことではありません。
        <br />
        会社としての意思決定は必要ですが働きやすい職場環境を形成することや、出勤率の算定自体に時間を要することから、法律を上回る措置を講じる企業も珍しくなくなっています。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0034
