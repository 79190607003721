import {graphql} from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import {ITopicPath} from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0034 from '../../../components/molecules/columnDetailInner0034'
import ColumnRelationCards0034 from '../../../components/molecules/columnRelationCards0034'
import UsefulFunctionLayout005 from '../../../components/molecules/usefulFunctionLayout005'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'
import Conclusion006 from '../../../components/molecules/Conclusion006'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0034 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '有給が発生しない？意外に知られていない休職時の有給付与について',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="有給休暇がもらえない？休職時における有給休暇の付与について"
        description="私傷病による休職の場合、出勤率算出の際に出勤とみなすことはできません。休職時により出勤率が8割に満たない場合の有給休暇の付与はどうなるか解説します。"
        ogUrl="https://kintaicloud.jp/column/details/C0034/"
        ogType="article"
        ogTitle="有給休暇がもらえない？休職時における有給休暇の付与について"
        ogDescription="私傷病による休職の場合、出勤率算出の際に出勤とみなすことはできません。休職時により出勤率が8割に満たない場合の有給休暇の付与はどうなるか解説します。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0034.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0034 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** CTA */}
        <Conclusion006 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout005 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事 */}
        <ColumnRelationCards0034 />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0034

export const pageQuery = graphql`
  query C0034 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
